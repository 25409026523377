import React from 'react';
import './App.css';
import Intro from '../Intro/Intro';
import Projects from '../Projects/Projects';
import Contact from '../Contact/Contact';
import About from '../About/About';
import Experience from '../Experience/Experience';
import Education from '../Education/Education';


function App() {
  return (
    <div className="site-container">
      <Intro />
      <About />
      <Experience />
      <Projects />
      <Education />
      <Contact />
    </div>
  );
}

export default App;
