import React from "react";
import "./Projects.css";
import waitcash from "../../images/waitcash.gif";
import cointrack from "../../images/cointrack.gif";
import investigate from "../../images/investigate.png";
import reconcile from "../../images/reconcile.png";

export default function Projects() {
  return (
    <>
      <h2 className="projects-header">Projects</h2>
      <div className="projects-container">
        <div className="project">
          <a href="https://medacist.com/drug-diversion-monitoring-software" target="_blank" rel="noreferrer">
            <img className="web-project-img" src={investigate} alt=""></img>
          </a>
        </div>
        <div className="project">
          <a href="https://medacist.com/medication-reconciliation-audit-tool" target="_blank" rel="noreferrer">
            <img className="web-project-img" src={reconcile} alt=""></img>
          </a>
        </div>
        <div className="project">
          <a href="https://github.com/elijahsimpsonn/Waitcash-Client" target="_blank" rel="noreferrer">
            <img className="mobile-project-img" src={waitcash} alt=""></img>
          </a>
        </div>
        <div className="project">
          <a href="https://github.com/elijahsimpsonn/CoinTrack" target="_blank" rel="noreferrer">
            <img className="mobile-project-img" src={cointrack} alt=""></img>
          </a>
        </div>
      </div>
    </>
  );
}